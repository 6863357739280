<template>
  <div class="textBoxMulti">
    <div class="textBoxMulti__inputWrapper">
      <Chip
        class="textBoxMulti__inputWrapper__chip"
        v-for="data of dataArray"
        :key="data"
        icon="X"
        @iconClick="onAddressDeleteClicked(data)"
      >
        {{ data }}
      </Chip>
      <input
        type="textbox"
        v-model="inputValue"
        :placeholder="dataArray.length ? '' : placeholder"
        :class="[{ dense: dense }, { error: isError }, { disabled: disabled }]"
        :pattern="pattern"
        @keypress.enter="onKeypressEnter"
        @blur="onBlur"
        @keydown.delete="onKeypressDelete"
        @focus="onInputFocus"
        :disabled="disabled"
      />
    </div>
    <TIcon
      @click.native="$emit('clickIcon')"
      class="textBoxMulti__icon"
      v-if="icon"
      :icon="icon"
      :color="getColor('black', 'black-60')"
    ></TIcon>
    <p class="error-message" v-if="isError">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'TextboxMulti',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    pattern: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    errorMessage: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      inputValue: '',
      dataArray: [],
      isFocused: false,
    }
  },
  computed: {
    styles() {
      return {
        color: this.color,
        'font-size': this.size,
      }
    },
    isError() {
      return this.errorMessage != ''
    },
  },
  watch: {
    value: function (newValue) {
      const diff = this.dataArray.filter((item) => newValue.indexOf(item) == -1)
      diff.map((item) => this.dataArray.push(item))
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.value) {
        this.value.map((item) => this.add(item))
      }
    },
    clear() {
      this.dataArray = []
    },
    add(data) {
      if (this.dataArray.filter((item) => item == data).length == 0) {
        this.dataArray.push(data)
        this.$emit('input', this.dataArray)
      }
    },
    onKeypressEnter() {
      this.submitValue()
    },
    onBlur() {
      this.isFocused = false
      this.submitValue()
    },
    submitValue() {
      if (this.validateMail()) {
        this.add(this.inputValue)
        this.inputValue = ''
      }
    },
    validateMail() {
      const regExp = new RegExp(
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      )
      return regExp.test(this.inputValue)
    },
    onKeypressDelete() {
      if (this.inputValue == '' && this.dataArray.length > 0) {
        this.onAddressDeleteClicked(this.dataArray.slice(-1)[0])
      }
    },
    onAddressDeleteClicked(address) {
      const newArray = this.dataArray.filter((item) => item != address)
      this.dataArray = newArray
      this.$emit('input', newArray)
    },
    onInputFocus() {
      this.isFocused = true
    },
  },
}
</script>

<style lang="scss" scoped>
.textBoxMulti {
  width: 100%;
  position: relative;

  &:focus {
    filter: drop-shadow(0px 0px 4px map-deep-get($palette, main, primary));
  }

  &__inputWrapper {
    border: solid 1px map-deep-get($palette, black, black-30);
    border-radius: 4px;
    padding: 4px;
    min-height: 44px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &__chip {
      display: inline-block;
      margin-right: 4px;
      margin-top: 2px;
      margin-bottom: 2px;
    }
    input {
      height: 26px;
      flex-grow: 1;
      border: none;
      font-size: 14px;
      text-overflow: ellipsis;
      transition: 0.5s;
      &::placeholder {
        color: map-deep-get($palette, black, black-60);
      }
      &.dense {
        height: 32px;
        font-size: 12px;
        padding: 7px 8px;
      }
      &.error {
        border-color: map-deep-get($palette, main, alert);
      }

      &.disabled {
        border-color: map-deep-get($palette, black, black-20);
        background-color: map-deep-get($palette, black, black-20);
        color: map-deep-get($palette, black, black-60);
        -webkit-text-fill-color: map-deep-get($palette, black, black-60);
        opacity: 1;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;
  }

  .error-message {
    color: map-deep-get($palette, main, alert);
    margin-top: 8px;
  }
}
</style>
