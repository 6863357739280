<template>
  <Sidenav
    class="sidenav"
    :items="sideMenu"
    :page="page"
    v-if="$auth.isAuthenticated && currentUser"
  ></Sidenav>
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu'

export default {
  components: {
    SidebarMenu,
  },
  data() {
    return {
      page: null,
      currentUser: null,
      sideMenu: [
        {
          to: `/${this.$route.params.directory}/`,
          alias: [`/${this.$route.params.directory}/?*`],
          title: 'ダッシュボード',
          icon: 'Dashboard',
          id: 'dashboard',
          disabled: true,
        },
        {
          to: `/${this.$route.params.directory}/summary`,
          alias: [`/${this.$route.params.directory}/summary/*`],
          title: '選考状況サマリー',
          icon: 'Lineargraph',
          id: 'summary',
          disabled: true,
        },
        {
          to: `/${this.$route.params.directory}/peoples`,
          alias: [`/${this.$route.params.directory}/people/*`],
          title: '候補者',
          icon: 'People',
          id: 'peoples',
          disabled: true,
        },
        {
          to: `/${this.$route.params.directory}/jobs`,
          alias: [`/${this.$route.params.directory}/job/*`],
          title: 'ポジション',
          icon: 'Document-One-Page',
          id: 'jobs',
          disabled: true,
          hidden: false,
        },
        {
          to: `/${this.$route.params.directory}/routes`,
          alias: [`/${this.$route.params.directory}/route/*`],
          title: '採用ルート',
          icon: 'Flag',
          id: 'routes',
          disabled: true,
          hidden: false,
        },
        {
          to: `/${this.$route.params.directory}/users`,
          alias: [`/${this.$route.params.directory}/user/*`],
          title: 'メンバー',
          icon: 'People-Checkmark',
          id: 'users',
          disabled: true,
          hidden: false,
        },
        {
          to: `/${this.$route.params.directory}/boilerplates`,
          alias: [`/${this.$route.params.directory}/boilerplate/*`],
          title: 'テンプレート',
          icon: 'Document-Edit',
          id: 'boilerplates',
          disabled: true,
          hidden: false,
        },
        {
          to: `/${this.$route.params.directory}/setup/profile`,
          alias: [`/${this.$route.params.directory}/setup/*`],
          title: '設定・変更',
          icon: 'Setting',
          id: 'setup',
          disabled: true,
        },
      ],
      collapsed: false,
    }
  },
  watch: {
    collapsed(val) {
      this.$parent.collapsed = val
    },
    currentUser() {
      this.sideMenu[0].disabled = false
      this.sideMenu[1].disabled = false
      this.sideMenu[2].disabled = false

      if (this.currentUser && this.currentUser.acl) {
        if (this.currentUser.acl.jobs && this.currentUser.acl.jobs.get) {
          this.sideMenu[3].disabled = false
          this.sideMenu[3].hidden = false
        } else {
          this.sideMenu[3].disabled = true
          this.sideMenu[3].hidden = true
        }

        if (
          this.currentUser.acl.routes &&
          this.currentUser.acl.routes.get &&
          this.currentUser.role !== 'agent'
        ) {
          this.sideMenu[4].disabled = false
          this.sideMenu[4].hidden = false
        } else {
          this.sideMenu[4].disabled = true
          this.sideMenu[4].hidden = true
        }

        if (
          this.currentUser.acl.users &&
          this.currentUser.acl.users.get &&
          this.currentUser.role !== 'agent'
        ) {
          this.sideMenu[5].disabled = false
          this.sideMenu[5].hidden = false
        } else {
          this.sideMenu[5].disabled = true
          this.sideMenu[5].hidden = true
        }

        if (
          this.currentUser.acl.boilerplates &&
          this.currentUser.acl.boilerplates.get &&
          this.currentUser.role !== 'consultant' &&
          this.currentUser.role !== 'agent'
        ) {
          this.sideMenu[6].disabled = false
          this.sideMenu[6].hidden = false
        } else {
          this.sideMenu[6].disabled = true
          this.sideMenu[6].hidden = true
        }
      }

      this.sideMenu[7].disabled = false
    },
  },
  created() {
    this.collapsed = this.$store.state.menuCollapsed
  },
  async mounted() {
    this.page = this.$parent.page

    this.$parent.collapsed = this.collapsed

    this.loadData()
  },
  methods: {
    onToggleCollapse(val) {
      this.collapsed = val
      this.$store.commit('setMenuCollapsed', this.collapsed)
    },
    loadData() {
      if (!this.$root.currentUser || !this.$root.currentUser.role) {
        setTimeout(() => {
          this.loadData()
        }, 300)
        return
      }
      this.$set(this, 'currentUser', this.$root.currentUser)
    },
  },
}
</script>

<style lang="scss">
.sidenav {
  z-index: 998;
  min-height: 100vh;
}
.sidenav .vsm--link_level-1.vsm--link_exact-active,
.sidenav .vsm--link_level-1.vsm--link_active {
  box-shadow: 0 0 0 0;
}
.sidenav .vsm--link_level-1.vsm--link_exact-active .vsm--icon,
.sidenav .vsm--link_level-1.vsm--link_active .vsm--icon {
  background-color: transparent !important;
  color: #fc9649;
}
.sidenav .vsm--link_level-1.vsm--link_exact-active:after,
.sidenav .vsm--link_level-1.vsm--link_active:after {
  position: absolute;
  content: '';
  height: 40px;
  width: 4px;
  background-color: map-deep-get($palette, main, primary);
  right: 0;
  top: calc(50% - 20px);
}
.sidenav .vsm--link_level-1.vsm--link_hover,
.sidenav .vsm--link_level-1:hover {
  color: #e83;
  background-color: transparent !important;
  font-weight: 600;
}
.sidenav .vsm--link_level-1.vsm--link_hover .vsm--icon,
.sidenav .vsm--link_level-1:hover .vsm--icon {
  color: #fc9649 !important;
}
.page {
  width: 100%;
}
.body {
  .right {
    width: calc(100vw - 5rem - 16rem);
    min-width: calc(991px - 5rem - 16rem);
    .table-responsive {
      & + .fixed-bottom {
        width: calc(100vw - 5rem - 16rem);
        left: calc(5rem + 16rem);
      }
    }
  }
  .table-responsive {
    & + .fixed-bottom {
      width: calc(100vw - 5rem);
      left: 5rem;
    }
  }
}
.body .detail,
.body .detail header,
.body .detail footer {
  width: calc(100vw - 5rem - 16rem);
  min-width: calc(991px - 5rem - 16rem);
}
.narrow {
  .body {
    .right {
      width: calc(100vw - 15rem - 16rem);
      min-width: calc(991px - 15rem - 16rem);
      .table-responsive {
        & + .fixed-bottom {
          width: calc(100vw - 15rem - 16rem);
          left: calc(15rem + 16rem);
        }
      }
    }
    .table-responsive {
      & + .fixed-bottom {
        width: calc(100vw - 15rem);
        left: 15rem;
      }
    }
  }
}
.narrow .body .detail,
.narrow .body .detail header,
.narrow .body .detail footer {
  width: calc(100vw - 15rem - 16rem);
  min-width: calc(991px - 15rem - 16rem);
}
</style>
