<template>
  <div class="wrapper">
    <textarea
      type="textbox"
      :value="value"
      :placeholder="placeholder"
      :class="[{ error: isError }]"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
      :style="textareaStyle"
    >
    </textarea>
    <p class="error-message" v-if="isError">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    height: {
      type: String,
    },
  },
  computed: {
    isError() {
      return this.errorMessage != ''
    },
    textareaStyle() {
      const style = {}
      if (this.height) {
        style.minHeight = this.height
      }
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  textarea {
    border: solid 1px map-deep-get($palette, black, black-30);
    font-size: 14px;
    border-radius: 4px;
    padding: 12px 8px;
    text-overflow: ellipsis;
    resize: vertical;
    width: 100%;
    &::placeholder {
      color: map-deep-get($palette, black, black-60);
    }
    &.error {
      border-color: map-deep-get($palette, main, alert);
    }
    &:focus {
      filter: drop-shadow(0px 0px 4px map-deep-get($palette, main, primary));
    }
  }
  .error-message {
    color: map-deep-get($palette, main, alert);
    margin-top: 8px;
  }
}
</style>
