import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'

import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import VueInfiniteLoading from 'vue-infinite-loading'
import VueSelect from 'vue-select'

import 'vue-select/dist/vue-select.css'

import dayjs from 'dayjs'

import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import {
  required,
  numeric,
  min,
  max,
  min_value,
  max_value,
  between,
  email,
  regex,
  confirmed,
} from 'vee-validate/dist/rules'
import { kana, tel, date, time } from './utils/rules'

import App from './App.vue'
import router from './router'
import { store } from './utils/store'
import { Auth0Plugin } from './plugins/auth'

import common from './mixins/common'
import color from './mixins/color'

import TIcon from '@/components/common/TIcon/TIcon'

import Header from '@/components/includes/Header'
import Menu from '@/components/includes/Menu'

Vue.use(VueHead)
Vue.use(VueRouter)

extend('required', required)
extend('numeric', numeric)
extend('min', min)
extend('max', max)
extend('min_value', min_value)
extend('max_value', max_value)
extend('between', between)
extend('email', email)
extend('regex', regex)
extend('confirmed', confirmed)
extend('kana', kana)
extend('tel', tel)
extend('date', date)
extend('time', time)

extend('isEmployableYear', {
  validate: (val) => {
    try {
      const years = dayjs().diff(`${String(val)}/1/1`, 'years')
      if (years < 10) {
        return false
      }
      if (years > 110) {
        return false
      }
      return true

      if (years > 110) {
        return false
      }
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },
  message: '雇用に適した年齢ではありません',
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('Header', Header)
Vue.component('Menu', Menu)

localize('ja', ja)

Vue.mixin(common)
Vue.mixin(color)

const domain = process.env.VUE_APP_AUTH0_DOMAIN
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID
const audience = process.env.VUE_APP_AUTH0_AUDIENCE

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  },
})

Vue.use(BootstrapVue)

Vue.use(VueSidebarMenu)

Vue.use(VueInfiniteLoading)

// toasted
import Toasted from 'vue-toasted'
Vue.use(Toasted)
const toastOption = {
  type: 'success',
  position: 'top-center',
  duration: 5000,
  className: 'targetToast',
  containerClass: 'targetToast--container',
  iconPack: 'custom-class',
  icon: 'messageIcon t-icon icon-Check-circle',
  keepOnHover: true,
  action: {
    icon: 't-icon icon-X',
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    },
  },
}
Vue.toasted.register(
  'success',
  (payload) => {
    return payload.message
  },
  {
    type: 'success',
    position: 'top-center',
    duration: 5000,
    className: 'targetToast',
    containerClass: 'targetToast--container',
    iconPack: 'custom-class',
    icon: 'messageIcon t-icon icon-Check-circle',
    keepOnHover: true,
    action: {
      icon: 't-icon icon-X',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      },
    },
  }
)
Vue.toasted.register(
  'error',
  (payload) => {
    return payload.message
  },
  {
    type: 'error',
    position: 'top-center',
    duration: 5000,
    className: 'targetToast',
    containerClass: 'targetToast--container',
    iconPack: 'custom-class',
    icon: 'messageIcon t-icon icon-Exclamation-circle',
    keepOnHover: true,
    action: {
      icon: 't-icon icon-X',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      },
    },
  }
)

Vue.config.productionTip = false

Vue.component('TIcon', TIcon)

// V2共通コンポーネントの読み込み
const files = require.context('./components/common', true, /\.vue$/)
const components = {}
files.keys().forEach((key) => {
  components[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default
})
Object.keys(components).forEach((key) => {
  Vue.component(key.split('/').slice(-1)[0], components[key])
})

new Vue({
  router,
  store,
  // Bootstrap,
  render: (h) => h(App),
  beforeCreate() {
    this.$store.commit('initializeStore')
  },
}).$mount('#app')

VueSelect.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement) =>
      createElement('TIcon', {
        props: {
          icon: 'Caret-down-fill',
          color: '#6B7B94',
        },
      }),
  },
  Deselect: {
    render: (createElement) =>
      createElement('TIcon', {
        props: {
          icon: 'X',
          color: '#43425D',
          size: '1rem',
        },
      }),
  },
})
Vue.component('VSelect', VueSelect)
