<template>
  <div class="wrapper d-flex">
    <div class="form-inline ml-auto mr-3" v-show="showDataCount">
      <TextPallet
        class="mr-2"
        tag="label"
        :color="getColor('black', 'black-60')"
        :size="12"
        >{{ currentPageMin }} - {{ currentPageMax }} /
        {{ totalRows }}件</TextPallet
      >
      <slot></slot>
    </div>
    <div class="paging">
      <ul class="paging-list m-0">
        <li class="paging-item" :class="{ disabled: currentPageEdited == 1 }">
          <a class="paging-link" href="#" @click.prevent="setPage(1)">
            <TIcon
              size="1.2rem"
              :color="currentPageEdited == 1 ? '#6B7B94' : '#2149B7'"
              icon="Chevron-double-left"
            ></TIcon
          ></a>
        </li>
        <li class="paging-item" :class="{ disabled: currentPageEdited == 1 }">
          <a
            class="paging-link"
            href="#"
            @click.prevent="setPage(currentPageEdited - 1)"
            :class="{ disable: currentPageEdited == 1 }"
          >
            <TIcon
              size="1.2rem"
              :color="currentPageEdited == 1 ? '#6B7B94' : '#2149B7'"
              icon="Chevron-left"
            ></TIcon
          ></a>
        </li>
        <li
          class="paging-item"
          v-for="num in showPagesFix"
          :key="num"
          :class="{ active: numFix(num) == currentPageEdited }"
        >
          <template v-if="numFix(num) == currentPageEdited"
            ><span class="paging-link active">{{ numFix(num) }}</span></template
          ><a
            class="paging-link"
            href="#"
            @click.prevent="setPage(numFix(num))"
            v-else
            >{{ numFix(num) }}</a
          >
        </li>
        <li
          class="paging-item"
          :class="{ disabled: currentPageEdited == totalPages }"
        >
          <a
            class="paging-link"
            href="#"
            @click.prevent="setPage(currentPageEdited + 1)"
          >
            <TIcon
              size="1.2rem"
              :color="currentPageEdited == totalPages ? '#6B7B94' : '#2149B7'"
              icon="Chevron-right"
            ></TIcon
          ></a>
        </li>
        <li
          class="paging-item"
          :class="{ disabled: currentPageEdited == totalPages }"
        >
          <a class="paging-link" href="#" @click.prevent="setPage(totalPages)">
            <TIcon
              size="1.2rem"
              :color="currentPageEdited == totalPages ? '#6B7B94' : '#2149B7'"
              icon="Chevron-double-right"
            ></TIcon
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // 現在のページ
    value: {
      type: Number,
    },
    // 全件数
    totalRows: {
      type: Number,
    },
    // 1ページあたりの表示項目数
    perPage: {
      type: Number,
    },
    // ページングに表示するページ数
    showPages: {
      type: Number,
    },
    // データ件数部分を表示するかどうか
    showDataCount: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPageEdited: Number,
    }
  },
  computed: {
    numFix() {
      return function (num) {
        const adjust = 1 + (this.showPages - 1) / 2
        let result = num
        if (this.currentPageEdited > this.showPages / 2) {
          result = num + this.currentPageEdited - adjust
        }
        if (this.currentPageEdited + this.showPages / 2 > this.totalPages) {
          result = this.totalPages - this.showPages + num
        }
        if (this.totalPages <= this.showPages) {
          result = num
        }
        return result
      }
    },
    showPagesFix() {
      if (this.totalPages < this.showPages) {
        return this.totalPages
      }
      return this.showPages
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage)
    },
    currentPageMax() {
      return this.currentPageEdited * this.perPage >= this.totalRows
        ? this.totalRows
        : this.currentPageEdited * this.perPage
    },
    currentPageMin() {
      return this.currentPageEdited * this.perPage - (this.perPage - 1)
    },
  },
  mounted() {
    this.currentPageEdited = this.value
  },
  methods: {
    setPage(page) {
      if (page <= 0) {
        this.currentPageEdited = 1
      } else if (page > this.totalPages) {
        this.currentPageEdited = this.totalPages
      } else {
        this.currentPageEdited = page
      }
      this.$emit('input', this.currentPageEdited)
    },
  },
}
</script>

<style lang="scss" scoped>
.paging-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  height: 44px;
  .paging-item {
    height: 100%;
    &:first-child {
      .paging-link {
        border-left: 1px solid map-deep-get($palette, black, black-30);
        border-radius: 54px 0 0 54px;
      }
    }
    &:last-child {
      .paging-link {
        border-radius: 0 54px 54px 0;
      }
    }
    .paging-link {
      width: 42px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid map-deep-get($palette, black, black-30);
      border-bottom: 1px solid map-deep-get($palette, black, black-30);
      border-right: 1px solid map-deep-get($palette, black, black-30);
      background-color: #fff;
      color: map-deep-get($palette, main, accent);
      text-decoration: none;
      &.active {
        background-color: map-deep-get($palette, main, accent);
        color: #fff;
      }
      &.disable {
        color: map-deep-get($palette, black, black-60);
      }
    }
  }
}
</style>
