<template>
  <div
    class="wrapper"
    tabindex="0"
    :class="[{ disabled: disabled }, { dense: dense }, type]"
  >
    <select
      ref="select"
      @change="onSelect"
      v-model="selectedValue"
      :disabled="disabled ? true : false"
    >
      <option
        v-for="option of options"
        :key="option.value"
        :value="option.value"
        :selected="option.value == defaultvalue"
      >
        {{ option.text }}
      </option>
    </select>
    <TIcon
      class="icon"
      icon="Caret-down-fill"
      :color="getColor('black', 'black-60')"
    ></TIcon>
  </div>
</template>

<script>
export default {
  name: 'Pulldown',
  props: {
    value: {
      default: null,
    },
    options: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  watch: {
    value: function (newValue) {
      this.defaultvalue = newValue
      this.selectedValue = newValue
    },
  },
  data() {
    return {
      defaultvalue: '',
      selectedValue: '',
    }
  },
  computed: {
    iconColor() {
      return '#6B7B94'
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.defaultvalue = this.value
      this.selectedValue = this.value
    },
    onSelect() {
      this.$emit('input', this.selectedValue)
    },
    select(val) {
      this.selectedValue = val
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  overflow: hidden;
  text-align: center;
  position: relative;
  border: 1px solid map-deep-get($palette, black, black-30);
  border-radius: 50px;
  background: #ffffff;
  height: 44px;
  transition: 0.5s;

  &.square {
    border-radius: 4px;
  }

  &.ghost {
    border-radius: 4px;
    border: unset !important;
  }

  &.dense {
    height: 32px;
    select {
      padding: 7px 0 7px 16px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    border-color: map-deep-get($palette, black, black-20);

    select {
      cursor: not-allowed;
      background-color: map-deep-get($palette, black, black-20);
      color: map-deep-get($palette, black, black-60);
      opacity: 1;
    }
  }

  select {
    width: 100%;
    padding: 13px 30px 13px 16px;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
    color: map-deep-get($palette, black, black-90);
    line-height: 100%;
    z-index: 1;
    transition: 0.5s;

    &::placeholder {
      color: map-deep-get($palette, black, black-60);
    }

    &::-ms-expand {
      display: none;
    }
  }

  &:hover {
    background-color: map-deep-get($palette, black, black-20);
  }

  &:focus {
    box-shadow: 0px 0px 4px 0px map-deep-get($palette, main, primary);
  }

  .icon {
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}
</style>
