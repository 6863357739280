<template>
  <div class="wrapper">
    <Button
      class="button"
      type="ghost"
      :color="getColor('black', 'black-60')"
      :label="userText"
      :icon="menuOpened ? 'Chevron-up' : 'Chevron-down'"
      iconPosition="right"
      v-click-outside="hide"
      @click="toggle"
    >
    </Button>
    <ul class="menu" v-if="menuOpened">
      <li
        class="menu-item"
        v-for="item of menu"
        :key="item.title"
        :class="{ separator: item.separator }"
        @click="item.onClick"
      >
        <hr v-if="item.separator" />
        <Button
          v-else
          type="ghost"
          :color="getColor('black', 'black-90')"
          :label="item.title"
          :icon="item.icon"
        ></Button>
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'UserInfo',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      menuOpened: false,
    }
  },
  props: {
    userName: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggle() {
      this.menuOpened = !this.menuOpened
    },
    hide() {
      this.menuOpened = false
    },
  },
  computed: {
    userText() {
      return `${this.companyName} / ${this.userName}`
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  color: map-deep-get($palette, black, black-90);
  font-size: 14px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  .button {
    font-weight: bold;
  }
  .menu {
    position: absolute;
    width: 280px;
    right: 0;
    top: 44px;
    list-style: none;
    padding: 0;
    margin: 0;
    border: solid 1px map-deep-get($palette, black, black-30);
    border-radius: 4px;
    padding: 16px 0;
    z-index: 999;
    background-color: #fff;
    .menu-item {
      cursor: pointer;
      padding-left: 15px;
      &:hover {
        background-color: map-deep-get($palette, black, black-20);
      }
      &.separator {
        &:hover {
          background-color: #fff;
          cursor: auto;
        }
      }
      hr {
        margin: 8px 0;
      }
      a {
        text-decoration: none;
        color: map-deep-get($palette, black, black-90);
        display: block;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
