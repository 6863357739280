<template>
  <div class="wrapper" :class="{ dense: dense }">
    <div
      class="logo"
      @click="handleLink(`/${$route.params.directory}/`)"
      :class="{ small: dense }"
    >
      <img :src="dense ? logoSmall : logo" />
    </div>
    <ul class="menu-list">
      <template v-for="item of menuItems">
        <template v-if="item.id === 'separator'">
          <li
            class="menu-item"
            :key="item.title"
            :class="[{ active: isCurrentPage(item.id) }]"
            @click.stop="switchMenu"
            :title="dense ? 'メニューを開く' : 'メニューを閉じる'"
          >
            <a class="switch" @click.stop="switchMenu">
              <transition>
                <h1 class="title title--switch" v-if="!dense">
                  メニューを閉じる
                </h1>
              </transition>
              <TIcon
                :icon="dense ? 'Menu' : 'Chevron-double-left'"
                size="1.6rem"
                :class="['icon', { 'icon--switch': !dense }]"
                color=""
              ></TIcon
            ></a>
          </li>
        </template>
        <template v-else>
          <li
            class="menu-item"
            :key="item.title"
            :class="[{ active: isCurrentPage(item.id) }]"
            @click="handleLink(item.to)"
            :title="item.title"
          >
            <a @click="handleLink(item.to)">
              <TIcon class="icon" :icon="item.icon" size="1.6rem"></TIcon>
              <transition name="fade">
                <h1 class="title" v-if="!dense">{{ item.title }}</h1>
              </transition>
            </a>
          </li>
        </template>
        <hr class="separator" v-if="item.id === 'separator'" />
      </template>
    </ul>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import TIcon from '../TIcon/TIcon.vue'

export default {
  name: 'Sidenav',
  components: {
    TIcon,
  },
  data() {
    return {
      logo: require('@/assets/images/logo.svg'),
      logoSmall: require('@/assets/images/logo-mini.svg'),
      menuExpanded: true,
    }
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    page: {
      type: Object,
      default: null,
    },
  },
  computed: {
    menuItems() {
      const menuItems = cloneDeep(this.items)
      menuItems.unshift({
        id: 'separator',
        title: 'separator',
      })
      return menuItems
    },
    dense() {
      return !this.menuExpanded
    },
  },
  methods: {
    switchMenu() {
      this.menuExpanded = !this.menuExpanded
    },
    isCurrentPage(id) {
      return id == this.page.id
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 239px;
  padding-top: 18px;
  background-color: #fff;
  transition: 0.3s;
  margin-top: -72px;
  border-right: solid 1px map-deep-get($palette, black, black-30);
  &.dense {
    width: 71px;
  }
  .logo {
    text-align: center;
    margin-bottom: 30px;
    img {
      width: 154px;
      height: 34px;
    }
    &.small {
      margin-left: 4px;
      img {
        width: auto;
      }
    }
  }
  ul {
    &.menu-list {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        &.menu-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 50px;
          padding: 13px 20px;
          box-sizing: border-box;
          border-left: 4px solid #fff;
          white-space: nowrap;
          cursor: pointer;
          a {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-decoration: none;
            &.switch {
              justify-content: space-between;
            }
          }
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            color: map-deep-get($palette, black, black-30);
            &--switch {
              color: map-deep-get($palette, black, black-90);
              margin: 0;
            }
          }
          .title {
            color: map-deep-get($palette, black, black-60);
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            &--switch {
              color: map-deep-get($palette, black, black-90);
            }
          }
          &.active {
            border-color: map-deep-get($palette, main, primary);
            .icon {
              color: map-deep-get($palette, main, primary);
            }
            .title {
              color: map-deep-get($palette, black, black-90);
            }
          }
          &:hover {
            .icon {
              color: map-deep-get($palette, main, primary);
            }
            .title {
              color: map-deep-get($palette, main, primary);
            }
          }
        }
      }
      .separator {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
