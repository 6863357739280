import Vue from 'vue'
import Vuex from 'vuex'

import dayjs from 'dayjs'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    locale: 'ja',
    directory: null,
    accessToken: null,
    expireIn: null,
    condition: null,
    menuCollapsed: false,
  },
  getters: {
    isConnected(state) {
      return state.isConnected
    },
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale
      localStorage.setItem('store', JSON.stringify(state))
    },
    setDirectory(state, directory) {
      if (directory) {
        state.directory = directory
        localStorage.setItem('store', JSON.stringify(state))
      }
    },
    async setMenuCollapsed(state, collapsed) {
      state.menuCollapsed = collapsed
      localStorage.setItem('store', JSON.stringify(state))
    },
    async setCondition(state, condition) {
      state.condition = condition
      localStorage.setItem('store', JSON.stringify(state))
    },
    async setAccessToken(state, accessToken) {
      state.accessToken = accessToken
      if (accessToken) {
        state.expireIn = dayjs().add(1, 'hours').toDate()
      } else {
        state.expireIn = null
      }
      await localStorage.setItem('store', JSON.stringify(state))
    },
    async clearAccessToken(state) {
      state.accessToken = null
      state.expireIn = null
      await localStorage.setItem('store', JSON.stringify(state))
    },
    initializeStore(state) {
      if (localStorage.getItem('store')) {
        state = Object.assign(state, JSON.parse(localStorage.getItem('store')))
      }
      localStorage.setItem('store', JSON.stringify(state))
    },
  },
})
