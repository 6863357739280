import dayjs from 'dayjs'

const kana = {
  validate(value) {
    return !!value.match(/^([\u3000-\u301C\u30A1-\u30F6\u30FB-\u30FE]){1,100}$/)
  },
  message(field) {
    return `${field}は全角カタカナで入力してください`
  },
}

const tel = {
  validate(value) {
    return !!value.match(/^0\d{1,4}-\d{1,4}-\d{4}$/)
  },
  message(field) {
    return `${field}のフォーマットが正しくありません`
  },
}

const date = {
  validate(value) {
    let ret =
      /^((19|20)\d\d)[/-]([1-9]|0[1-9]|1[012])[/-]([1-9]|0[1-9]|[12][0-9]|3[01])$/.test(
        value
      )
    if (ret) {
      const year = Number(RegExp.$1)
      const month = Number(RegExp.$3)
      const day = Number(RegExp.$4)

      const strDate = dayjs(`${year}-${month}-${day}`).format('YYYYMMDD')
      const _year = Number(strDate.substr(0, 4))
      const _month = Number(strDate.substr(4, 2))
      const _day = Number(strDate.substr(6, 2))

      if (year !== _year || month !== _month || day !== _day) {
        ret = false
      }
    }
    return ret
  },
  message(field) {
    return `${field}のフォーマットが正しくありません`
  },
}

const time = {
  validate(value) {
    let ret = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
    if (ret) {
      const hour = Number(RegExp.$1)
      const minute = Number(RegExp.$3)

      const strTime = dayjs(`2000-01-01 ${hour}:${minute}:00`).format('HHmm')
      const _hour = Number(strTime.substr(0, 2))
      const _minute = Number(strTime.substr(2, 2))

      if (hour !== _hour || minute !== _minute) {
        ret = false
      }
    }
    return ret
  },
  message(field) {
    return `${field}のフォーマットが正しくありません`
  },
}

export { kana, tel, date, time }
