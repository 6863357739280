import { isEqual, isObject, cloneDeep, transform } from 'lodash'

import 'dayjs/locale/ja'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
// import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.locale('ja')
dayjs.extend(localizedFormat)
// dayjs.extend(relativeTime)

const conditions = {
  null: {
    icon: '',
    title: 'データ集計中',
    description:
      '採用コンディションはただいま集計中です<br>利用開始から約1ヶ月程度で算出されます。',
  },
  excellent: {
    icon: 'excellent',
    title: 'Excellent!!',
    description: '素晴らしい状態です！<br>そのままキープ！',
  },
  verygood: {
    icon: 'verygood',
    title: 'Very Good!',
    description:
      'とても良い調子です！<br>%%segment%%を改善すればさらに良くなります！',
  },
  good: {
    icon: 'good',
    title: 'Good',
    description: '全体的には良い調子です。<br>ただ%%segment%%は要注意です！',
  },
  bad: {
    icon: 'bad',
    title: 'Bad',
    description:
      'あまり良くない状態です…。<br>%%segment%%の改善に取り組みましょう！',
  },
  verybad: {
    icon: 'verybad',
    title: 'Very Bad…',
    description:
      'かなり悪い状態です…。<br>まずは%%segment%%の改善から頑張りましょう！',
  },
  clear: {
    icon: 'fas fa-bullseye',
    title: 'Clear!',
    description: '採用目標クリア',
  },
  finish: {
    icon: 'finish',
    title: 'Finish',
    description: '採用期日終了',
  },
}

const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
]

const roles = {
  admin: 'Admin（Attack）',
  master: '管理者',
  manager: '採用責任者',
  recruiter: '採用担当者',
  staff: '選考担当者',
  agent: 'エージェント',
  consultant: '採用コンサルタント',
}

const categories = {
  media: '求人メディア',
  agent: 'エージェント',
  referrer: '社員紹介',
  direct: '直接応募',
  other: 'その他',
}

const steps = {
  10: '書類選考',
  20: '筆記試験',
  30: '選考会',
  40: '面談',
  50: '面接',
  90: 'その他',
}

const results = {
  pass: '合格',
  fail: '不合格',
  decline: '辞退',
  terminate: '選考終了',
  engage: '内定承諾',
  refuse: '内定辞退',
}

const occupations = {
  100: '経営企画',
  200: '事業企画',
  300: 'マーケティング',
  400: '営業',
  500: 'エンジニア',
  600: 'デザイナー',
  700: 'コンサルタント',
  800: '人事',
  900: '経理/財務',
  1000: '広報',
  1100: '総務',
  9000: 'その他',
}

const positions = {
  100: '代表取締役',
  200: '経営幹部（CxO/役員）',
  300: 'マネジャー（部長クラス）',
  400: 'リーダー（課長クラス）',
  500: 'メンバー（担当クラス）',
  600: '専門職',
  700: 'スタッフ/アシスタント',
  900: 'その他',
}

const employments = {
  10: '役員',
  20: '正社員',
  30: '契約社員',
  40: '派遣社員',
  50: '業務委託',
  60: 'アルバイト',
  90: 'その他',
}

const academicQualifications = {
  10: '大学院',
  20: '大学',
  30: '専門学校',
  40: '高専',
  50: '高校',
  90: 'その他',
}

const careerTypes = {
  10: '正社員',
  20: '派遣労働者',
  30: '契約社員',
  40: 'パート',
  50: 'アルバイト',
  60: '業務委託',
  70: 'テレワーカー',
  80: '役員',
  90: 'その他',
}

const segments = [
  'IT/WEB',
  'コンサルティング',
  '人材',
  'メディア/エンタテインメント',
  'メディカル',
  'メーカー',
  '金融',
  '不動産/建築',
  '商社',
  'サービス',
  '流通/小売',
  'インフラ',
  'その他',
]

const monthLabels = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月',
]

const boilerplateTypes = {
  10: 'コメント投稿',
  30: '選考設定',
  40: '選考結果',
  70: '内定承諾結果',
  80: '入社決定',
  90: 'メール送信',
}

const jobEnables = {
  true: '受付中',
  false: '停止中',
}

function getParameterByName(name) {
  const match = RegExp(`[#&]${name}=([^&]*)`).exec(window.location.hash)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

function clone(obj) {
  return cloneDeep(obj)
}

function difference(obj, base) {
  function changes(obj, base) {
    return transform(obj, function (res, val, key) {
      if (!isEqual(val, base[key])) {
        res[key] =
          isObject(val) && isObject(base[key]) ? changes(val, base[key]) : val
      }
    })
  }
  return changes(obj, base)
}

function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

function getCondition(val) {
  return conditions[val] ? conditions[val] : {}
}

function optionsStep() {
  const options = []
  for (const [key, value] of Object.entries(steps)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getStepTitle(val) {
  val = Number(val)
  return steps[val] ? steps[val] : '-'
}

function getResultTitle(val) {
  return results[val] ? results[val] : '-'
}

function optionsPrefecture() {
  const options = []
  for (let i = 0; i < prefectures.length; i++) {
    options.push({
      value: prefectures[i],
      text: prefectures[i],
    })
  }
  return options
}

function optionsMonth() {
  const options = []
  for (let i = 1; i <= 12; i++) {
    options.push({
      value: i,
      text: String(i),
    })
  }
  return options
}

function optionsRole() {
  const options = []
  for (const [key, value] of Object.entries(roles)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getRoleTitle(val) {
  return roles[val] ? roles[val] : '-'
}

function optionsCategory() {
  const options = []
  for (const [key, value] of Object.entries(categories)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getCategoryTitle(val) {
  return categories[val] ? categories[val] : '-'
}

function optionsOccupation() {
  const options = []
  for (const [key, value] of Object.entries(occupations)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getOccupationTitle(val) {
  return occupations[val] ? occupations[val] : '-'
}

function optionsPosition() {
  const options = []
  for (const [key, value] of Object.entries(positions)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getPositionTitle(val) {
  return positions[val] ? positions[val] : '-'
}

function optionsEmployment() {
  const options = []
  for (const [key, value] of Object.entries(employments)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getEmploymentTitle(val) {
  return employments[val] ? employments[val] : '-'
}

function optionsAcademicQualification() {
  const options = []
  for (const [key, value] of Object.entries(academicQualifications)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getAcademicQualificationTitle(val) {
  return academicQualifications[val] ? academicQualifications[val] : '-'
}

function optionsCareerType() {
  const options = []
  for (const [key, value] of Object.entries(careerTypes)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getCareerTypeTitle(val) {
  return careerTypes[val] ? careerTypes[val] : '-'
}

function optionsSegment() {
  const options = []
  for (let i = 0; i < segments.length; i++) {
    options.push({
      value: segments[i],
      text: segments[i],
    })
  }
  return options
}

function getGender(val) {
  if (!val || val === '0') {
    return '不明'
  }
  if (val === '1') {
    return '男性'
  }
  if (val === '2') {
    return '女性'
  }
  if (val === '9') {
    return '適用不能'
  }
  return '-'
}

function getAge(val, val2) {
  let birthday
  if (!val) {
    return 0
  }
  birthday = dayjs(val)

  let today
  if (!val2) {
    today = dayjs()
  } else {
    today = dayjs(val2)
  }

  const years = Math.abs(birthday.diff(today, 'years'))
  const months = Math.abs(birthday.diff(today, 'months')) - years * 12
  if (months < 0) {
    return years - 1
  }
  if (years < 0) {
    return 0
  }
  return years
}
function getYearMonth(val) {
  if (val && val.match(/^([0-9]+)(-|\/)([0-9]+)$/)) {
    const y = Number(RegExp.$1)
    const m = Number(RegExp.$3)
    return dayjs(new Date(y, m - 1, 1)).format('YYYY/MM')
  }
  return ''
}

function getMonthLabels() {
  return monthLabels
}

function optionsBoilerplateType() {
  const options = []
  for (const [key, value] of Object.entries(boilerplateTypes)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getBoilerplateTypeTitle(val) {
  return boilerplateTypes[val] ? boilerplateTypes[val] : '-'
}

function optionsJobEnable() {
  const options = []
  for (const [key, value] of Object.entries(jobEnables)) {
    options.push({
      value: key,
      text: value,
    })
  }
  return options
}

function getJobEnableTitle(val) {
  return jobEnables[val] ? jobEnables[val] : '-'
}

export {
  getParameterByName,
  clone,
  difference,
  wait,
  getCondition,
  optionsPrefecture,
  optionsMonth,
  optionsRole,
  getRoleTitle,
  optionsCategory,
  getCategoryTitle,
  optionsStep,
  getStepTitle,
  getResultTitle,
  optionsOccupation,
  getOccupationTitle,
  optionsPosition,
  getPositionTitle,
  optionsEmployment,
  getEmploymentTitle,
  optionsAcademicQualification,
  getAcademicQualificationTitle,
  optionsCareerType,
  getCareerTypeTitle,
  optionsSegment,
  getGender,
  getAge,
  getYearMonth,
  getMonthLabels,
  optionsBoilerplateType,
  getBoilerplateTypeTitle,
  optionsJobEnable,
  getJobEnableTitle,
}
