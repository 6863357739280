<template>
  <div
    class="wrapper"
    :class="[{ disabled: disabled }, { checked: inputValue }]"
    tabindex="0"
    @click="toggle"
  >
    <label :for="`checkbox-${uniqueId}`">
      <slot>{{ label }}</slot>
    </label>
    <TIcon
      class="icon"
      :icon="inputValue ? 'Check' : ''"
      size="1rem"
      :color="iconColor"
      :style="colorStyle"
    ></TIcon>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    label: {
      type: String,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () =>
        console.log('checkbox changed but no function is assigned'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.checked
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
    uniqueId() {
      const strong = 1000
      return (
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16)
      )
    },
    colorStyle() {
      const style = {}
      if (this.checked) {
        style['background-color'] = this.getColor('main', 'accent')
      }
      return style
    },
    iconColor() {
      if (this.disabled) {
        return this.getColor('black', 'black-30')
      }
      if (this.checked) {
        return 'white'
      }
      return '#6B7B94'
    },
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.inputValue = !this.inputValue
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 44px;
  width: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid map-deep-get($palette, black, black-30);
  border-radius: 44px;
  padding-left: 16px;
  padding-right: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  cursor: pointer;
  background-color: #fff;
  label {
    margin-bottom: 0;
    margin-right: 8px;
    cursor: pointer;
  }
  &:hover {
    background-color: map-deep-get($palette, black, black-20);
  }
  &.disabled {
    color: map-deep-get($palette, black, black-30);
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
    label {
      cursor: not-allowed;
    }
  }
  &.checked {
    border-color: map-deep-get($palette, main, accent);
    color: map-deep-get($palette, main, accent);
  }
  &:focus {
    box-shadow: 0px 0px 4px 0px map-deep-get($palette, main, primary);
  }
  input {
    visibility: hidden;
    width: 0;
    height: 0;
    &:checked {
      & + label {
        &::after {
          background-color: map-deep-get($palette, main, accent);
          color: #fff;
        }
      }
    }
    &:disabled {
      & + label {
        color: map-deep-get($palette, black, black-30);
        &::after {
          color: map-deep-get($palette, black, black-30);
        }
      }
    }
    &:focus {
      & + label {
        &::after {
          color: #f00;
        }
      }
    }
  }
  .icon {
    border-radius: 50%;
    border: 1px solid map-deep-get($palette, black, black-30);
    width: 24px;
    height: 24px;
    padding: 3px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
