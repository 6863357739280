const COLOR_PALLET = {
  main: {
    primary: '#E24508',
    accent: '#2149B7',
    alert: '#C21745',
  },
  score: {
    excellent: '#D43C60',
    verygood: '#E24508',
    good: '#239E63',
    bad: '#3B6C8F',
    verybad: '#0139CA',
    faintExcellent: '#D43C604D',
    faintVerygood: '#E245084D',
    faintGood: '#239E634D',
    faintBad: '#3B6C8F4D',
    faintVerybad: '#0139CA4D',
  },
  black: {
    'black-a': '#FFFFFF',
    'black-10': '#F5F9FC',
    'black-20': '#E7EAF1',
    'black-30': '#CFD6E0',
    'black-40': '#AAB6C4',
    'black-50': '#8898A8',
    'black-60': '#6B7B94',
    'black-70': '#505F7E',
    'black-80': '#424771',
    'black-90': '#43425D',
    'black-100': '#0A2540',
  },
  violet: {
    'violet-20': '#ECFBFF',
    'violet-30': '#C4EFFF',
    'violet-40': '#9DDCFF',
    'violet-50': '#88BBFD',
    'violet-60': '#7794F8',
    'violet-70': '#6772E5',
    'violet-80': '#565ABE',
    'violet-90': '#43468B',
    'violet-100': '#33325C',
  },
  blue: {
    'blue-20': '#E6F7FF',
    'blue-30': '#B7E8FF',
    'blue-40': '#8FCAFD',
    'blue-50': '#68A4F8',
    'blue-60': '#447BE9',
    'blue-70': '#3162D3',
    'blue-80': '#2149B7',
    'blue-90': '#112E98',
    'blue-100': '#071E79',
  },
  leaf: {
    'leaf-20': '#F3FEEE',
    'leaf-30': '#D5FACE',
    'leaf-40': '#ADF1B6',
    'leaf-50': '#74E5A3',
    'leaf-60': '#3ECF8E',
    'leaf-70': '#23B47D',
    'leaf-80': '#159670',
    'leaf-90': '#0A7362',
    'leaf-100': '#035551',
  },
  orange: {
    'orange-20': '#FEFCE3',
    'orange-30': '#FCF4B3',
    'orange-40': '#FEE883',
    'orange-50': '#FBD767',
    'orange-60': '#F4BF59',
    'orange-70': '#E39F4A',
    'orange-80': '#CF7B38',
    'orange-90': '#B6572B',
    'orange-100': '#9E331F',
  },
  red: {
    'red-20': '#FEEDED',
    'red-30': '#FFCBCB',
    'red-40': '#FEA5A5',
    'red-50': '#FF7B7B',
    'red-60': '#F86874',
    'red-70': '#E25167',
    'red-80': '#C21745',
    'red-90': '#9E133A',
    'red-100': '#7C0E2B',
  },
  grape: {
    'grape-20': '#F5F8FF',
    'grape-30': '#FEE1F3',
    'grape-40': '#FFC7EE',
    'grape-50': '#F6A4EC',
    'grape-60': '#D682DA',
    'grape-70': '#B76BC5',
    'grape-80': '#9251AB',
    'grape-90': '#6A3891',
    'grape-100': '#4D2379',
  },
}

export default {
  methods: {
    getColor(group, name) {
      if (COLOR_PALLET[group][name]) {
        return COLOR_PALLET[group][name]
      } else {
        return '#000000'
      }
    },
  },
}
