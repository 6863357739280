<template>
  <div class="wrapper" :class="[{ opened: isOpen }, { focus: isFocus }]">
    <a class="toggle-button" @click="open">
      <TIcon icon="Search" size="1.5rem"></TIcon
    ></a>
    <transition name="slide">
      <div class="input-wrapper" v-if="isOpen">
        <input
          type="text"
          ref="input"
          :placeholder="placeholder"
          :value="value"
          @input="$emit('input', $event.target.value)"
          @focus="isFocus = true"
          @blur="isFocus = false"
        />
        <a class="toggle-button" @click="clear">
          <TIcon icon="X-circle" size="1.5rem"></TIcon>
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import TIcon from '../TIcon/TIcon'

export default {
  name: 'SearchInput',
  components: {
    TIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      isFocus: false,
    }
  },
  methods: {
    async open() {
      await (this.isOpen = true)
      this.$refs.input.focus()
    },
    clear() {
      this.value = ''
      this.isOpen = false
      this.$emit('input', '')
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 44px;
  height: 44px;
  border: 1px solid map-deep-get($palette, black, black-30);
  box-sizing: border-box;
  border-radius: 44px;
  display: flex;
  color: map-deep-get($palette, black, black-60);
  transition: 0.5s;
  background-color: #fff;
  a {
    color: map-deep-get($palette, black, black-60);
    text-decoration: none;
  }
  &.opened {
    width: auto;
    border-radius: 40px;
    .toggle-button {
      width: 44px;
    }
    &.focus {
      a {
        color: map-deep-get($palette, main, primary);
      }
    }
    &:hover {
      background-color: #fff;
    }
  }
  .toggle-button {
    justify-content: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .input-wrapper {
    display: flex;
    width: 100%;
    input {
      color: map-deep-get($palette, black, black-60);
      border: none;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
  &:hover {
    background-color: map-deep-get($palette, black, black-20);
  }
}
.slide-enter-active {
  transition: opacity 0.5s;
}
.slide-enter {
  opacity: 0;
}
</style>
