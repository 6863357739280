<template>
  <button
    @click="$emit('click', $event.target)"
    :class="[
      { disabled: disabled },
      type,
      { small: small },
      { reversed: isIconRight },
    ]"
    :disabled="disabled ? true : false"
    :style="colorStyle"
  >
    <TIcon
      class="icon"
      v-if="icon"
      :icon="icon"
      :size="iconSize"
      :color="iconColor"
    ></TIcon>
    <span v-if="!type.match('icon')">
      <slot>{{ label }}</slot>
    </span>
  </button>
</template>

<script>
import TIcon from '../TIcon/TIcon'

export default {
  name: 'Button',
  components: {
    TIcon,
  },
  props: {
    label: {
      type: String,
      default: 'Button',
    },
    onClick: {
      type: Function,
      default: () => console.log('button clicked but no function is assigned'),
    },
    type: {
      type: String,
      default: 'fill',
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    color: {
      type: String,
      default: '#2149B7', // TODO use color palette
    },
  },
  computed: {
    isIconRight() {
      return this.iconPosition == 'right'
    },
    colorStyle() {
      const style = {}
      if (this.type.match('fill')) {
        style['background-color'] = this.color
      } else if (this.type.match('outline')) {
        style['border-color'] = this.color
        style.color = this.color
      } else if (this.type.match('ghost')) {
        style.color = this.color
      }
      return style
    },
    iconSize() {
      return this.small ? '1.2rem' : '1.5rem'
    },
    iconColor() {
      if (this.disabled) {
        return this.getColor('black', 'black-30')
      }
      return this.type == 'fill' ? 'white' : this.color
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  min-width: 80px;
  height: 44px;
  padding: 13px;
  border-radius: 4px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  span {
    font-weight: bold;
    font-size: 14px;
  }
  .icon {
    margin-right: 14.75px;
  }
  &:hover {
    opacity: 0.6;
  }
  &.reversed {
    flex-direction: row-reverse;
    span {
      margin-right: 14.75px;
    }
    .icon {
      margin-right: 0;
    }
  }
  &.small {
    height: 32px;
    span {
      font-size: 12px;
      line-height: 12px;
    }
    .icon {
      margin-right: 8.5px;
    }
    &.reversed {
      span {
        margin-right: 8.5px;
      }
    }
  }
  &:focus {
    box-shadow: 0px 0px 4px 0px map-deep-get($palette, main, primary);
  }
  &.fill {
    background: map-deep-get($palette, main, accent);
    color: #fff;
    &:hover {
      background: #447be9;
    }
    &.disabled {
      background-color: map-deep-get($palette, black, black-30) !important;
    }
  }
  &.outline {
    border-color: map-deep-get($palette, main, accent);
    border: 1px solid;
    color: map-deep-get($palette, main, accent);
    &:hover {
      border-color: #8fcafd;
      color: #8fcafd;
    }
    &.disabled {
      border-color: map-deep-get($palette, black, black-30) !important;
      color: map-deep-get($palette, black, black-30) !important;
    }
  }
  &.ghost {
    color: map-deep-get($palette, main, accent);
    &:hover {
      color: #8fcafd;
    }
    &.disabled {
      color: map-deep-get($palette, black, black-30) !important;
    }
  }
  &.icon {
    width: 44px;
    min-width: unset;
    border-radius: 50%;
    .icon {
      margin-right: 0;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
}
</style>
