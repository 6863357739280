import axios from 'axios'

// import assert from 'assert'

import urlJoin from 'url-join'

const APP_DOMAIN = process.env.VUE_APP_APP_DOMAIN
let API_URL = process.env.VUE_APP_API_URL

const checkUrl = APP_DOMAIN.replace('*', '(.+?)')
if (window.location.origin.match(`https?://${checkUrl}`)) {
  API_URL = API_URL.replace('*', RegExp.$1)
}

function getDirectory() {
  try {
    return JSON.parse(localStorage.getItem('store')).directory
  } catch (e) {
    return null
  }
}

function getAccessToken() {
  try {
    return JSON.parse(localStorage.getItem('store')).accessToken
  } catch (e) {
    return null
  }
}

async function getData(url, option) {
  option = option ? option : {}

  const headers = {
    'Content-Type': 'application/json',
  }

  const directory = getDirectory()
  if (directory) {
    headers.Directory = directory
  }

  const accessToken = getAccessToken()
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  if (option.headers) {
    for (const key of Object.keys(option.headers)) {
      headers[key] = option.headers[key]
    }
  }

  const axiosOptions = { headers: headers }
  if (option.axiosOptions) {
    for (const key of Object.keys(option.axiosOptions)) {
      axiosOptions[key] = option.axiosOptions[key]
    }
  }

  try {
    const response = await axios.get(
      urlJoin(API_URL, url, `?logStamp=${new Date().getTime()}`),
      axiosOptions
    )
    return new Promise((resolve) => {
      if (option.raw == true) {
        resolve(response)
      }
      resolve(response.data)
    })
  } catch (err) {
    if (err.response && err.response.data) {
      if (
        err.response.data.message === 'Need login' &&
        url.match(/myprofile/)
      ) {
        // alert('ユーザー未登録')
        // login()
      } else if (
        err.response.data.message === 'Unregistered Company' &&
        url.match(/myprofile/)
      ) {
        alert('Incorrect Directory')
        window.location.href = process.env.VUE_APP_PORTAL_URL
      } else if (err.response.data.error === 'Unauthorized') {
        alert(err.response.data.message)
        // logout()
        // window.location.href = '/'
      }
    }
    console.log('err:', err)
  }
}

function postData(url, json) {
  const headers = {
    'Content-Type': 'application/json',
  }

  const directory = getDirectory()
  if (directory) {
    headers.Directory = directory
  }

  const accessToken = getAccessToken()
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return axios
    .post(urlJoin(API_URL, url, `?logStamp=${new Date().getTime()}`), json, {
      headers,
    })
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data)
      })
    })
}

function patchData(url, json) {
  const headers = {
    'Content-Type': 'application/json',
  }

  const directory = getDirectory()
  if (directory) {
    headers.Directory = directory
  }

  const accessToken = getAccessToken()
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return axios
    .patch(urlJoin(API_URL, url, `?logStamp=${new Date().getTime()}`), json, {
      headers,
    })
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data)
      })
    })
}

function deleteData(url) {
  const headers = {}

  const directory = getDirectory()
  if (directory) {
    headers.Directory = directory
  }

  const accessToken = getAccessToken()
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return axios
    .delete(urlJoin(API_URL, url, `?logStamp=${new Date().getTime()}`), {
      headers,
    })
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data)
      })
    })
}

function postFiles(url, formData) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  const directory = getDirectory()
  if (directory) {
    headers.Directory = directory
  }

  const accessToken = getAccessToken()
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return axios
    .post(
      urlJoin(API_URL, url, `?logStamp=${new Date().getTime()}`),
      formData,
      { headers }
    )
    .then((response) => {
      return new Promise((resolve) => {
        resolve(response.data)
      })
    })
}

export { getData, postData, patchData, deleteData, postFiles }
