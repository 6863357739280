<template>
  <i class="t-icon" :class="`icon-${icon}`" :style="styles" />
</template>

<script>
export default {
  name: 'TIcon',
  props: {
    icon: {
      type: String,
      default: 'Add-1',
    },
    color: {
      type: String,
    },
    size: {
      type: String,
      default: '2rem',
    },
  },
  computed: {
    styles() {
      return {
        color: this.color,
        'font-size': this.size,
      }
    },
  },
}
</script>
