<template>
  <div class="wrapper">
    <input
      :value="value"
      :placeholder="placeholder"
      :class="[{ dense: dense }, { error: isError }, { disabled: disabled }]"
      :type="type"
      :pattern="pattern"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
      :disabled="disabled"
      v-bind="$attrs"
    />
    <p class="error-message" v-if="isError">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'Textbox',
  inheritAttrs: false,
  props: {
    value: {
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    pattern: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    errorMessage: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        color: this.color,
        'font-size': this.size,
      }
    },
    isError() {
      return this.errorMessage != ''
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  input {
    border: solid 1px map-deep-get($palette, black, black-30);
    height: 44px;
    font-size: 14px;
    border-radius: 4px;
    padding: 12px 8px;
    text-overflow: ellipsis;
    width: 100%;
    transition: 0.5s;
    &::placeholder {
      color: map-deep-get($palette, black, black-60);
    }
    &.dense {
      height: 32px;
      font-size: 12px;
      padding: 7px 8px;
    }
    &.error {
      border-color: map-deep-get($palette, main, alert);
    }
    &:focus {
      filter: drop-shadow(0px 0px 4px map-deep-get($palette, main, primary));
    }
    &.disabled {
      border-color: map-deep-get($palette, black, black-20);
      background-color: map-deep-get($palette, black, black-20);
      color: map-deep-get($palette, black, black-60);
      -webkit-text-fill-color: map-deep-get($palette, black, black-60);
      opacity: 1;
    }
  }
  .error-message {
    color: map-deep-get($palette, main, alert);
    margin-top: 8px;
  }
}
</style>
