<template>
  <div class="wrapper" :class="[{ small: small }]">
    <div
      class="main-wrapper"
      :class="[{ disabled: disabled }]"
      tabindex="0"
      @click="toggle"
    >
      <input
        :id="`checkbox-${uniqueId}`"
        type="checkbox"
        :disabled="disabled"
        v-model="inputValue"
      />
      <TIcon
        class="mr-2"
        icon="Check"
        :class="['icon', { checked: inputValue }, { disabled: disabled }]"
        size="1rem"
        :color="iconColor"
        :style="colorStyle"
      ></TIcon>
      <label :for="`checkbox-${uniqueId}`" @click="toggle">
        <slot>{{ label }}</slot>
      </label>
    </div>
    <TextPallet
      class="description"
      tag="span"
      :color="getColor('black', 'black-60')"
      :size="12"
    >
      <slot name="description">{{ description }}</slot>
    </TextPallet>
  </div>
</template>

<script>
import TIcon from '../TIcon/TIcon'

export default {
  name: 'CheckboxSimple',
  components: {
    TIcon,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'Button',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () =>
        console.log('checkbox changed but no function is assigned'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.checked
      },
      set(newValue) {
        this.$emit('change', newValue)
        this.$emit('input', newValue)
      },
    },
    uniqueId() {
      const strong = 1000
      return (
        new Date().getTime().toString(16) +
        Math.floor(strong * Math.random()).toString(16)
      )
    },
    colorStyle() {
      const style = {}
      if (this.disabled) {
        if (this.checked) {
          style['background-color'] = this.getColor('black', 'black-30')
        } else {
          style['background-color'] = '#FFFFFF'
        }
      } else if (this.checked) {
        style['background-color'] = this.getColor('main', 'accent')
      }
      return style
    },
    iconColor() {
      if (this.disabled) {
        return 'white'
      }
      if (this.checked) {
        return 'white'
      }
      return 'white'
    },
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.inputValue = !this.inputValue
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  .main-wrapper {
    height: 44px;
    width: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    label {
      margin-bottom: 0;
      cursor: pointer;
    }
    &.disabled {
      color: map-deep-get($palette, black, black-30);
      cursor: not-allowed;
      input {
        cursor: not-allowed;
      }
      label {
        cursor: not-allowed;
      }
    }
    input {
      visibility: hidden;
      width: 0;
      height: 0;
      &:checked {
        & + label {
          &::after {
            background-color: map-deep-get($palette, main, accent);
            color: #fff;
          }
        }
      }
      &:disabled {
        & + label {
          color: map-deep-get($palette, black, black-30);
          &::after {
            color: map-deep-get($palette, black, black-30);
          }
        }
      }
      &:focus {
        & + label {
          &::after {
            color: #f00;
          }
        }
      }
    }
    .icon {
      border-radius: 4px;
      width: 24px;
      height: 24px;
      padding: 3px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid map-deep-get($palette, black, black-30);
      cursor: pointer;
      &.checked {
        border-color: map-deep-get($palette, main, accent);
      }
      &.disabled {
        border-color: map-deep-get($palette, black, black-30);
        cursor: not-allowed;
      }
    }
  }
  .description {
    margin-left: 33px;
    display: block;
  }
  &.small {
    .main-wrapper {
      height: 16px;
    }
    .icon {
      border-radius: 2px;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
