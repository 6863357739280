<template>
  <div class="wrapper">
    <table :class="{ noBottomBorder: noBottomBorder }">
      <tr class="header-row">
        <th
          class="header-cell"
          v-for="headItem in headers"
          :key="headItem.label"
        >
          <span :class="[{ sorted: headItem.key == currentSortTarget }]"
            >{{ headItem.label }}
            <TIcon
              class="sort-icon"
              v-if="headItem.sortable !== false"
              :icon="headItem.icon"
              size="1.2rem"
              :color="getColor('black', 'black-60')"
              @click.native="sort(headItem)"
            ></TIcon
          ></span>
        </th>
      </tr>
      <tbody>
        <tr
          class="data-row"
          v-for="(item, i) of sortedItems"
          @click="onRowClick(item)"
          :class="[{ selectable: selectable }]"
          :key="i"
        >
          <td class="data-cell" v-for="headItem of headers" :key="headItem.key">
            <slot :name="headItem.key" v-bind:item="item">
              <template v-if="headItem.formatter">{{
                headItem.formatter(item[headItem.key], headItem.key, item)
              }}</template>
              <template v-else>{{ item[headItem.key] }}</template>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { sortBy } from 'lodash'

export default {
  name: 'Table',
  data() {
    return {
      currentSortTarget: '',
      currentSortDirectionAsc: true,
    }
  },
  created() {
    this.updateIcon()
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    noBottomBorder: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sort(sortTarget) {
      if (this.currentSortTarget == sortTarget.key) {
        // 昇順->降順（連続クリック）
        this.currentSortDirectionAsc = !this.currentSortDirectionAsc
      } else {
        // 昇順（初回クリック）
        this.currentSortDirectionAsc = true
        this.currentSortTarget = sortTarget.key
      }
      this.updateIcon()
    },
    updateIcon() {
      this.headers.map((item) => {
        if (item.key == this.currentSortTarget) {
          item.icon = this.currentSortDirectionAsc ? 'Down-arrow' : 'Up-arrow'
        } else {
          item.icon = 'Reverse-arrow'
        }
      })
    },
    onRowClick(item) {
      if (this.selectable) this.$emit('row-selected', item)
    },
  },
  computed: {
    sortedItems() {
      if (this.currentSortDirectionAsc) {
        return sortBy(this.items, this.currentSortTarget)
      }
      return sortBy(this.items, this.currentSortTarget).reverse()

      return sortBy(this.items, this.currentSortTarget).reverse()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  table {
    width: 100%;
    background-color: #fff;
    tr {
      border-bottom: solid map-deep-get($palette, black, black-30) 1px;
    }
    &.noBottomBorder {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .header-cell {
      padding-left: 32px;
      padding-top: 16px;
      padding-bottom: 16px;
      color: map-deep-get($palette, black, black-90);
      span {
        display: flex;
        font-weight: normal;
        .sort-icon {
          margin-left: 8px;
          cursor: pointer;
          border-radius: 0.6rem;
          &:hover {
            background-color: map-deep-get($palette, black, black-10);
          }
        }
        &.sorted {
          font-weight: bold;
        }
      }
    }
    .data-row {
      &.selectable {
        cursor: pointer;
      }
      &:hover {
        background-color: map-deep-get($palette, black, black-10);
      }
      .data-cell {
        height: 72px;
        padding-left: 32px;
        padding-top: 8px;
        padding-bottom: 8px;
        &:last-child {
          padding-right: 32px;
        }
      }
    }
  }
}
</style>
