<template>
  <compnent :is="tag" :class="[type, { bold: bold }]" :style="styles">
    <slot></slot>
  </compnent>
</template>

<script>
export default {
  name: 'TextPallet',
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    type: {
      type: String,
      default: 'normal',
    },
    bold: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 16,
    },
    color: {
      type: String,
      default: '#43425D',
    },
  },
  computed: {
    styles() {
      const style = {}
      style['font-size'] = `${this.size}px`
      style.color = this.color
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.normal {
  line-height: 150%;
}
.dense {
  line-height: 100%;
}
.comfort {
  line-height: 180%;
}
.bold {
  font-weight: bold;
}
</style>
