<template>
  <div class="wrapper">
    <TIcon class="icon" :icon="icon" size="1.4rem" :color="alertColor"></TIcon>
    <div class="message" :style="`color:${alertColor}`">{{ message }}</div>
    <a class="link" @click="$emit('linkClick')">{{ linkText }}</a>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    icon: {
      type: String,
      default: 'Exclamation-triangle',
    },
    type: {
      type: String,
      default: 'warning',
    },
    message: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
    },
  },
  computed: {
    alertColor() {
      switch (this.type) {
        case 'warning':
          return '#C21745'
        default:
          return '#C21745'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 8px;
  }
  .message {
    margin-right: 8px;
  }
  .link {
    cursor: pointer;
  }
}
</style>
