<template>
  <div class="file text-nowrap mr-2">
    <TIcon class="mr-1" icon="Clip" size="1.2rem" />
    <TextPallet
      class="name"
      tag="span"
      :size="10"
      :color="getColor('black', 'black-90')"
    >
      {{ fileName }}
    </TextPallet>
    <a
      class="removeUpdateFiles"
      variant="link"
      size="sm"
      @click="onClickDelete"
    >
      削除
    </a>
  </div>
</template>

<script>
export default {
  name: 'FileItem',
  props: {
    fileName: {
      type: String,
    },
  },
  methods: {
    onClickDelete(event) {
      this.$emit('clickDelete', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.file {
  height: 2rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  background-color: map-deep-get($palette, black, black-20);
  width: 50%;
  padding: 5px 9px;
  .removeUpdateFiles {
    margin-left: auto;
    cursor: pointer;
  }
}
</style>
