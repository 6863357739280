<template>
  <header>
    <div class="mask" v-if="mask">
      <b-spinner class="preloader"></b-spinner>
    </div>
    <b-navbar
      class="default-layout-navbar col-12 p-0 d-flex flex-row"
      ref="navbar"
      v-if="currentUser"
      variant="black-10"
    >
      <b-navbar-nav class="navbar-nav-right ml-auto">
        <Button
          class="button"
          type="ghost"
          :color="getColor('black', 'black-60')"
          @click="clickNews"
          >お知らせ</Button
        >
        <div class="separator mx-4"></div>
        <UserInfo
          :userName="currentUser.name"
          :companyName="currentUser.Company.name"
          :menu="userMenu"
        ></UserInfo>
      </b-navbar-nav>
    </b-navbar>
    <b-modal
      id="modal-changeCompany"
      title="会社の切り替え"
      dialog-class="switchCompany-modalDialog"
    >
      <b-list-group v-if="currentUser &amp;&amp; currentUser.otherCompanies">
        <b-list-group-item
          button="button"
          v-for="(otherCompany, index) in companiesToShow"
          :key="index"
          @click="doChangeCompany(otherCompany)"
        >
          <TextPallet
            :size="16"
            :color="getColor('black', 'black-90')"
            tag="span"
            >{{
              otherCompany && otherCompany.name ? otherCompany.name : '?'
            }}</TextPallet
          >
        </b-list-group-item>
      </b-list-group>
      <template v-if="currentUser &amp;&amp; currentUser.otherCompanies">
        <Pagination
          class="mt-3"
          v-if="currentUser.otherCompanies.length > 6"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="currentUser.otherCompanies.length"
          :show-pages="5"
          @currentPage="pageChanged"
        ></Pagination>
      </template>
    </b-modal>
  </header>
</template>

<script>
import dayjs from 'dayjs'
import { getData, postData } from '@/utils/api'
import { clone } from '@/utils/libs'

const otherUrl = `${process.env.VUE_APP_APP_PROTOCOL}://${process.env.VUE_APP_APP_DOMAIN}/*/`

export default {
  data() {
    return {
      app: {
        name: process.env.VUE_APP_NAME,
      },
      currentUser: null,
      messages: [],
      dismissCount: 0,
      mask: false,
      currentPage: 1,
      perPage: 6,
    }
  },
  watch: {
    '$parent.mask': function (val) {
      this.mask = this.$parent.mask
    },
  },
  async created() {
    this.$store.commit('setDirectory', this.$route.params.directory)
    this.$root.directory = this.$route.params.directory

    if (!this.$auth.isAuthenticated) {
      this.login()
    }

    let accessToken
    if (
      this.$store.state.accessToken &&
      dayjs(this.$store.state.expireIn).toDate() > new Date()
    ) {
      accessToken = this.$store.state.accessToken
    } else {
      accessToken = await this.$auth.getTokenSilently()
      this.$store.commit('setAccessToken', accessToken)
    }
    if (this.$auth.isAuthenticated && accessToken) {
      this.loadData()
    }
  },
  computed: {
    userMenu() {
      const userMenu = [
        {
          title: 'マイプロフィール',
          onClick: () => {
            this.handleLink(`/${this.$route.params.directory}/setup/profile`)
          },
          icon: 'Person',
        },
        {
          title: 'パスワードリセット',
          onClick: () => {
            this.doResetPassword()
          },
          icon: 'Key',
        },
      ]
      if (
        this.currentUser &&
        this.currentUser.otherCompanies &&
        this.currentUser.otherCompanies.length > 0
      ) {
        userMenu.push(
          { separator: true, onClick: () => {} },
          {
            title: '会社の切り替え',
            onClick: () => {
              this.$bvModal.show('modal-changeCompany')
            },
            icon: 'Office',
          }
        )
      }
      userMenu.push(
        { separator: true, onClick: () => {} },
        {
          title: 'ログアウト',
          onClick: () => {
            this.doLogout()
          },
          icon: 'Signout',
        }
      )
      return userMenu
    },
    companiesToShow() {
      if (this.currentUser && this.currentUser.otherCompanies) {
        return this.currentUser.otherCompanies.slice(
          this.perPage * (this.currentPage - 1),
          this.perPage * this.currentPage
        )
      }
      return []
    },
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    async loadData() {
      await getData(`/myprofile/`)
        .then((datum) => {
          if (datum) {
            this.$set(this, 'currentUser', clone(datum))
            this.$parent.currentUser = clone(datum)
            this.$root.currentUser = clone(datum)
          } else {
            this.$bvModal
              .msgBoxOk('登録情報の取得に失敗しました')
              .then(async () => {
                await this.$store.commit('clearAccessToken')
                this.$auth.logout()
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })

      if (this.$parent.prepareData) {
        await this.$parent.prepareData()
      } else if (this.$parent.reloadData) {
        await this.$parent.reloadData()
      } else if (this.$parent.loadData) {
        await this.$parent.loadData()
      } else {
        this.checkCondition()
      }

      if (
        this.$route.query &&
        this.$route.query.f &&
        this.$route.query.f === 'verify'
      ) {
        // verify email
        const isOtherEmail =
          this.currentUser.otherEmails &&
          this.currentUser.otherEmails.find((otherEmail) => {
            return otherEmail.email === this.$route.query.email
          })
        if (isOtherEmail && !isOtherEmail.state) {
          try {
            await postData(`/myprofile/verified/`, {
              email: this.$route.query.email,
            })
              .then((json) => {
                if (json && json.email) {
                  this.msgBoxError(
                    `予備のメールアドレスが有効化されました`,
                    async () => {
                      this.handleLink(
                        `/${this.$route.params.directory}/setup/profile`
                      )
                    }
                  )
                } else {
                  this.$router.replace(`/${this.$route.params.directory}/`)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          } catch (e) {
            console.log(e)
          }
        } else {
          this.$router.replace(`/${this.$route.params.directory}/`)
        }
      }

      this.$forceUpdate()
    },
    reload() {
      this.$nextTick(() => {
        this.loadData()
      })
    },
    async checkCondition() {
      const message = await this.CheckCondition()
      if (message) {
        this.messages.push(message)
        this.dismissCount = 30
      }
    },
    dismissChanged(dismissCount) {
      this.dismissCount = dismissCount
      if (this.dismissCount === 0) {
        this.messages = []
      }
    },
    doChangeCompany(company) {
      this.$bvModal
        .msgBoxConfirm('会社を切り替えますか？', {
          title: '会社の切り替え',
          okTitle: 'はい',
          cancelTitle: 'いいえ',
          hideHeaderClose: false,
        })
        .then((res) => {
          if (res) {
            window.location.href = otherUrl.replace('*', company.directory)
          }
        })
    },
    pageChanged(page) {
      this.currentPage = page
    },
    clickNews() {
      this.handleLink(`/${this.$route.params.directory}/news`)
    },
  },
}
</script>

<style scoped lang="scss">
header {
  position: relative;
  .navbar {
    height: 72px;
  }
  .mask {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 999999;
    .preloader {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -16px;
      margin-top: -16px;
    }
  }
  .popups {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    padding-left: 5rem;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
  }
  .separator {
    border-left: 1px solid map-deep-get($palette, black, black-30);
  }
}

header {
  .dropdown-menu {
    z-index: 10000;
  }
}
.main {
  &.narrow {
    .popups {
      padding-left: 15rem;
    }
  }
}
</style>
