import Vue from 'vue'
import Router from 'vue-router'

import 'dayjs/locale/ja'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'

import { authGuard } from '../plugins/auth'

dayjs.locale('ja')
dayjs.extend(LocalizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(calendar)

Vue.filter('ago', (value) => {
  let str = ''
  const diff = dayjs().diff(value, 'day')
  if (diff === 0) {
    if (dayjs().format('MD') === dayjs(value).format('MD')) {
      str += '今日'
    } else {
      str += '昨日'
    }
  } else if (diff === 1) {
    if (dayjs().format('MD') === dayjs(value).format('MD')) {
      str += '昨日'
    } else {
      str += '一昨日'
    }
  } else if (diff === 2) {
    if (dayjs().format('MD') === dayjs(value).format('MD')) {
      str += '一昨日'
    } else {
      str += '3日前'
    }
  } else if (diff > 0) {
    str += `${diff}日前`
  } else {
    str += `${Math.abs(diff)}日後`
  }
  str += ' '
  str += dayjs(value).format('LT')
  return str
})

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    { path: '/index.html', redirect: '/' },
    {
      path: '/',
      name: 'Redirect',
      component: () => import('@/components/Redirect'),
      beforeEnter: authGuard,
    },

    {
      path: '/resetPassword',
      name: 'Reset Password',
      component: () => import('@/components/ResetPassword'),
    },

    {
      path: '/:directory/',
      name: 'Dashboard',
      component: () => import('@/components/Dashboard'),
      beforeEnter: authGuard,
    },

    {
      path: '/:directory/summary',
      name: 'Summary',
      component: () => import('@/components/Summary'),
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/setup/:feature',
      name: 'Setup',
      component: () => import('@/components/Setup'),
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/peoples',
      name: 'List Peoples',
      component: () => import('@/components/Peoples'),
      props: { mode: 'list' },
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/jobs',
      name: 'List Jobs',
      component: () => import('@/components/Jobs'),
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/routes',
      name: 'List Routes',
      component: () => import('@/components/Routes'),
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/users',
      name: 'List Users',
      component: () => import('@/components/Users'),
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/boilerplates',
      name: 'List Boilerplates',
      component: () => import('@/components/Boilerplates'),
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/news',
      name: 'News',
      component: () => import('@/components/News'),
      beforeEnter: authGuard,
    },
    {
      path: '/:directory/news/:id',
      name: 'NewsDetail',
      component: () => import('@/components/NewsDetail'),
      beforeEnter: authGuard,
    },

    { path: '/:directory/*', component: () => import('@/components/NotFound') },
  ],
})
