<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import './assets/scss/style';
@import './assets/scss/v2/extensions';
</style>
