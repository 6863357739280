<template>
  <div class="fileInput-wrapper">
    <label
      class="fileInput-labelWrapper"
      :class="{ over: isDragOver }"
      @dragover.prevent="onDrag('over')"
      @dragleave="onDrag('leave')"
      @drop.stop="onDrop"
      for="input"
    >
      <input id="input" type="file" title @input="onInput" ref="input" />
      <div class="fileInput-main mr-2">
        <TextPallet
          tag="span"
          :color="getColor('black', 'black-60')"
          :size="12"
          >{{ getPlaceholder }}</TextPallet
        >
      </div>
      <Button
        v-if="!isDragOver"
        class="fileInput-button"
        type="outline"
        :color="getColor('black', 'black-60')"
        small
      >
        ファイルを選択
      </Button>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Textbox',
  data() {
    return {
      isDragOver: false,
    }
  },
  props: {
    value: {
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    pattern: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    errorMessage: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        color: this.color,
        'font-size': this.size,
      }
    },
    isError() {
      return this.errorMessage != ''
    },
    getPlaceholder() {
      return this.isDragOver
        ? 'ドロップしてファイルをアップロード'
        : this.placeholder
    },
  },
  methods: {
    onDrag(type) {
      console.log(type)
      this.isDragOver = type === 'over'
    },
    onDrop(event) {
      this.isDragOver = false
      const { files } = event.dataTransfer
      this.emitInput(files[0])
    },
    onInput(event) {
      const { files } = event.target
      this.emitInput(files[0])
    },
    emitInput(file) {
      this.$emit('input', file)
    },
    openFileDialogue() {
      this.$refs.input.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.fileInput-wrapper {
  width: 100%;

  .fileInput-labelWrapper {
    display: flex;
    align-items: center;

    input {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    .fileInput-main {
      width: 100%;
      height: 32px;
      border: 1px solid map-deep-get($palette, black, black-30);
      border-radius: 4px;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 8px 7px;
    }

    .fileInput-button {
      min-width: 116px;
      background-color: white;
    }

    &.over {
      .fileInput-main {
        border-style: dashed;
      }
    }
  }
}
</style>
