<template>
  <div class="chip">
    <div class="chip__container">
      <span>
        <slot></slot>
      </span>
      <TIcon
        v-if="icon"
        :icon="icon"
        size="16px"
        @click.native="$emit('iconClick')"
      ></TIcon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    icon: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.chip {
  display: inline-block;
  padding: 5px 4px;
  background-color: map-deep-get($palette, black, black-20);
  border-radius: 4px;
  color: map-deep-get($palette, black, black-90);
  font-size: 12px;

  &__container {
    display: flex;
    align-items: center;
  }
}
</style>
