import { isEqual } from 'lodash'

import { saveAs } from 'file-saver'
import axios from 'axios'

import pdf from 'pdfobject'
import { getData, postData } from '../utils/api'

import { clone, difference } from '../utils/libs'
import queryString from 'query-string'

// import marked from 'marked'

const { marked } = require('marked')

const APP_DOMAIN = process.env.VUE_APP_APP_DOMAIN
let API_URL = process.env.VUE_APP_API_URL

const checkUrl = APP_DOMAIN.replace('*', '(.+?)')
if (window.location.origin.match(`https?://${checkUrl}`)) {
  API_URL = API_URL.replace('*', RegExp.$1)
}

/*
function getAccessToken () {
  try {
    return JSON.parse(localStorage.getItem('store')).accessToken
  } catch (e) {
    return null
  }
}
*/

const messages = {
  // Common
  'Unregistered Company': '未登録のテナントです',
  'Need Login': 'ログインが必要です',
  'Not Found Data': 'データが見つからないか権限がないため表示できません',
  'Not Changed Data': 'データが変更されていません',
  'Permission Denied': '許可されていない操作です',
  'Unauthorized Operation': '許可されていない操作です',
  'Invalid Parameters': '不正なパラメータです',
  'Invalid Inputs': '入力内容が正しくありません',
  "Can't updated": '更新に失敗しました',
  // Profile
  'Incorrect OtherEmails': '予備のメールアドレスが正しくありません',
  // User
  'Empty User Name': '名前が未入力です',
  'Incorrect User Name': '名前が正しくありません',
  'Empty Email': 'メールアドレスが未入力です',
  'Incorrect Email': 'メールアドレスが正しくありません',
  'Incorrect Company': '会社名が正しくありません',
  'Incorrect Department': '部署が正しくありません',
  'Incorrect Position': '役職が正しくありません',
  'Incorrect Mobile': '携帯電話番号が正しくありません',
  'Incorrect Role': '権限ロールが正しくありません',
  'Inuse User For Jobs': 'ポジション情報で利用中のため削除できません',
  "Can't Change Role Due To Assigned Permissions":
    'ポジション情報に割り当て済みの権限があるため変更できません',
  'Failed Add Auth0 Data': 'メンバーの認証サービスへの登録に失敗しました',
  'Not Found Destination User': '送信先が見つかりません',
  // Company
  'Empty Company Name': '会社名が未入力です',
  'Incorrect Company Name': '会社名が正しくありません',
  'Empty Headcount': '社員数が未入力です',
  'Incorrect Headcount': 'が正しくありません',
  'Incorrect FoundationDate': '設立年月日が正しくありません',
  'Incorrect Segment': '業界が正しくありません',
  'Empty President': '代表者名が未入力です',
  'Incorrect President': '代表者名が正しくありません',
  'Empty URL': 'URLが未入力です',
  'Incorrect URL': 'URLが正しくありません',
  'Incorrect Zip': '郵便番号が正しくありません',
  'Empty Prefecture': '都道府県が未入力です',
  'Incorrect Prefecture': '都道府県が正しくありません',
  'Empty Locality': '市区町村・番地が未入力です',
  'Incorrect Locality': '市区町村・番地が正しくありません',
  'Incorrect Building': 'ビル・マンション名が正しくありません',
  'Incorrect Salary': '年収が正しくありません',
  'Incorrect TEL': '電話番号が正しくありません',
  'Empty Locations': '勤務地が未入力です',
  'Incorrect Locations': '勤務地が正しくありません',
  // People
  'Person Is Kept': '保留されている候補者です',
  // Job
  'Empty Job Name': 'ポジション名が未入力です',
  'Incorrect Job Name': 'ポジション名が正しくありません',
  'Empty Occupation': '職種が未入力です',
  'Incorrect Occupation': '職種が正しくありません',
  'Empty Position': '役職が未入力です',
  'Not Include Yourself':
    '管理者以外は自身を担当者から除外することはできません',
  // 'Incorrect Position': '役職が正しくありません',
  'Empty Employment': '雇用形態役職が未入力です',
  'Incorrect Employment': '雇用形態が正しくありません',
  'Empty Description': '業務内容が未入力です',
  'Incorrect Description': 'が正しくありません',
  'Incorrect Salary(min)': '年収(下限)が正しくありません',
  'Incorrect Salary(max)': '年収(上限)が正しくありません',
  // 'Incorrect Salary': '年収が正しくありません',
  'Incorrect Age(min)': '年齢(下限)が正しくありません',
  'Incorrect Age(max)': '年齢(上限)が正しくありません',
  'Incorrect Age': '年齢が正しくありません',
  'Incorrect Recruitments': '採用人数が正しくありません',
  'Incorrect Due Date': '採用期日が正しくありません',
  'Incorrect Launch Date': '採用開始日が正しくありません',
  'Incorrect Due Or Launch Date': '採用期日または採用開始日が正しくありません',
  'Required Managers': '採用責任者が必須です',
  'Required Recruiters': '採用担当者が必須です',
  'Required Staffs': '選考担当者が必須です',
  'Unauthorized Managers': '採用責任者が正しくありません',
  'Unauthorized Recruiters': '採用担当者が正しくありません',
  'Unauthorized Staffs': '選考担当者が正しくありません',
  'Unauthorized Agents': 'エージェントが正しくありません',
  'Unauthorized Consultants': '採用コンサルタントが正しくありません',
  'Empty Steps': '選考ステップが未入力です',
  'Incorrect Steps': '選考ステップが正しくありません',
  // 'Empty Locations': '勤務地が未入力です',
  // 'Incorrect Locations': '勤務地が正しくありません',
  'Incorrect Qualification': '応募資格・スキルが正しくありません',
  'Incorrect Sub Qualification': '歓迎する資格・スキルが正しくありません',
  'Incorrect Educations': '学歴情報が正しくありません',
  'Incorrect Careers': '職歴情報が正しくありません',
  'Inuse Job For Peoples': '候補者情報で利用中のため削除できません',
  // Route
  'Empty Category': '種別が未入力です',
  'Incorrect Category': '種別が正しくありません',
  'Empty Route Name': '採用ルート名が未入力です',
  'Incorrect Route Name': '採用ルート名が正しくありません',
  // 'Incorrect URL': 'URLが正しくありません',
  // 'Incorrect Department': '部署が正しくありません',
  'Incorrect Person': '担当者名が正しくありません',
  // 'Incorrect Email': 'メールアドレスが正しくありません',
  'Incorrect Note': '備考が正しくありません',
  // Other
  'Duplicate Email': 'メールアドレスが重複しています',
  'Registered Email': 'メールアドレスは登録済みです',
  // Users
  'Not Set Creditcard': 'クレジットカード情報を先に設定してください',
  'Empty Card Number': 'カード番号が未入力です',
  'Incorrect Card Number': 'カード番号が正しくありません',
  'Empty Card Exp Month': 'カード有効期限（月）が未入力です',
  'Incorrect Card Exp Month': 'カード有効期限（月）が正しくありません',
  'Empty Card Exp Year': 'カード有効期限（年）が未入力です',
  'Incorrect Card Exp Year': 'カード有効期限（年）が正しくありません',
  'Incorrect Card Exp': 'カード有効期限が正しくありません',
  'Empty Card Name': 'カード名義人が未入力です',
  'Incorrect Card Name': 'カード名義人が正しくありません',
  'Empty Card CSC': 'セキュリティコードが未入力です',
  'Incorrect Card CSC': 'セキュリティコードが正しくありません',
  'Failed Save Creditcard': 'クレジットカードカード情報の保存に失敗しました',
  "Can't Delete Own": '自身の情報を削除することはできません',
  // Boilerplates
  'Empty Boilerplate Type': '種別が未入力です',
  'Incorrect Boilerplate Type': '種別が正しくありません',
  'Empty Boilerplate Caption': 'メッセージタイトルが未入力です',
  'Incorrect Boilerplate Caption': 'メッセージタイトルが正しくありません',
  'Empty Boilerplate Text': 'メッセージ内容が未入力です',
  'Incorrect Boilerplate Text': 'メッセージ内容が正しくありません',
}

export default {
  methods: {
    handleLink(path) {
      this.$router.push(
        path,
        () => {},
        () => {}
      )
    },
    preview(url, name, tagId) {
      let ext = ''
      if (url.match(/\.(.+)$/)) {
        ext = RegExp.$1
      }
      try {
        const { accessToken } = JSON.parse(localStorage.getItem('store'))
        const { directory } = JSON.parse(localStorage.getItem('store'))

        axios
          .get(API_URL + url, {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Directory: directory,
            },
          })
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type,
            })
            if (
              ext === 'png' ||
              ext === 'jpeg' ||
              ext === 'gif' ||
              ext === 'bmp' ||
              ext === 'svg'
            ) {
              document.getElementById(tagId).src = URL.createObjectURL(blob)
            } else if (ext === 'pdf') {
              const fileUrl = URL.createObjectURL(blob)
              pdf.embed(fileUrl, `#${tagId}`)
            } else if (ext === 'markdown') {
              const reader = new FileReader()
              reader.onload = () => {
                document.getElementById(tagId).innerHTML = marked(reader.result)
              }
              reader.readAsText(blob)
            } else {
              // saveAs(blob, name)
            }
          })
          .catch((err) => {
            throw err
          })
      } catch (e) {
        console.log(e)
        alert('Failed Preview')
      } finally {
        // mask = false
      }
    },
    download(url, name) {
      try {
        const { accessToken } = JSON.parse(localStorage.getItem('store'))
        const { directory } = JSON.parse(localStorage.getItem('store'))

        axios
          .get(API_URL + url, {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Directory: directory,
            },
          })
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type,
            })
            saveAs(blob, name)
          })
          .catch((err) => {
            throw err
          })
      } catch (e) {
        console.log(e)
        alert('Failed Download')
      }
    },
    msgBoxConfirm(message, cb1, cb2, option) {
      option = option || {}
      if (!option.okTitle) {
        option.okTitle = 'はい'
      }
      if (!option.cancelTitle) {
        option.cancelTitle = 'いいえ'
      }
      this.$bvModal.msgBoxConfirm(message, option).then((val) => {
        if (val) {
          if (cb1) {
            cb1()
          }
        } else if (cb2) {
          cb2()
        }
      })
    },
    msgBoxError(orgMessage, cb, option) {
      const message = messages[orgMessage] ? messages[orgMessage] : orgMessage
      option = option || {}
      if (!option.title) {
        option.title = 'エラー'
      }
      if (!option.okTitle) {
        option.okTitle = '閉じる'
      }
      this.$bvModal.msgBoxOk(message, option).then(() => {
        if (cb) {
          cb()
        }
      })
    },
    doLogout() {
      // const title = 'ログアウト'
      const message = this.$createElement('div', {
        domProps: {
          innerHTML: `今すぐ、ログアウトしてもよろしいですか？<br>ログアウト後はサービストップページに移動します。`,
        },
      })

      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'ログアウト',
          okVariant: 'danger',
          okTitle: 'はい',
          cancelTitle: 'いいえ',
        })
        .then(async (val) => {
          if (val) {
            await this.$store.commit('clearAccessToken')
            await this.$auth.logout()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    doResetPassword() {
      // const title = 'パスワードのリセット'
      const message = this.$createElement('div', {
        domProps: {
          innerHTML: `登録されているメールアドレスに、パスワード変更リクエストを送ります。<br>アカウント保護のため、推測しにくいパスワードを設定してください。`,
        },
      })

      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'パスワードリセット',
          hideHeaderClose: false,
          okVariant: 'primary',
          okTitle: 'メールを送信する',
          cancelTitle: 'キャンセル',
        })
        .then(async (val) => {
          if (val) {
            await postData(`/myprofile/changePassword`)
              .then(async (ret) => {
                this.$bvModal.msgBoxOk(
                  'パスワード変更リクエストを送信しました',
                  {
                    title: '送信完了',
                    okVariant: 'secondary',
                    okTitle: '閉じる',
                  }
                )
                this.isSent = true
              })
              .catch((err) => {
                console.log(err)
                this.$bvModal.msgBoxOk(
                  'パスワード変更リクエストの送信に失敗しました',
                  {
                    // size: 'lg'
                  }
                )
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async CheckCondition() {
      const filters = [
        {
          jobId: {
            $exists: false,
          },
        },
        {
          peopleId: {
            $exists: false,
          },
        },
      ]

      const query = queryString.stringify({
        filter: JSON.stringify({ $and: filters }),
        sort: '-computedAt',
        limit: 1,
      })

      let message = null

      await getData(`/conditions?${query}`).then((data) => {
        if (data && data.length > 0) {
          const newCondition = clone(data[0])
          let oldCondition

          if (newCondition.condition && newCondition.score) {
            if (this.$store.state.condition) {
              oldCondition = this.$store.state.condition
            }
            if (oldCondition && !isEqual(oldCondition, newCondition)) {
              const diffCondition = difference(oldCondition, newCondition)
              if (diffCondition.score) {
                if (diffCondition.score.quality) {
                  if (
                    oldCondition.score.quality.pass.score <
                    newCondition.score.quality.pass.score
                  ) {
                    message =
                      '一次選考の《合格者》が出たため、【クオリティ】のコンディションがアップ！'
                  } else if (
                    oldCondition.score.quality.pass.score >
                    newCondition.score.quality.pass.score
                  ) {
                    message =
                      '一次選考の《不合格者》が出たため、【クオリティ】のコンディションがダウン…'
                  } else if (
                    oldCondition.score.quality.employ.score <
                    newCondition.score.quality.employ.score
                  ) {
                    message =
                      '《内定者》が出たため、【クオリティ】のコンディションがアップ！'
                  } else if (
                    oldCondition.score.quality.employ.score >
                    newCondition.score.quality.employ.score
                  ) {
                    message = '【クオリティ】のコンディションがダウン…'
                  }
                } else if (diffCondition.score.volume) {
                  if (
                    oldCondition.score.volume.pass.score <
                    newCondition.score.volume.pass.score
                  ) {
                    message =
                      '《書類選考通過者》が出たため、【ボリューム】のコンディションがアップ！'
                  } else if (
                    oldCondition.score.volume.pass.score >
                    newCondition.score.volume.pass.score
                  ) {
                    message = '【ボリューム】のコンディションがダウン…'
                  } else if (
                    oldCondition.score.volume.under.score <
                    newCondition.score.volume.under.score
                  ) {
                    message = '【ボリューム】のコンディションがアップ！'
                  } else if (
                    oldCondition.score.volume.under.score >
                    newCondition.score.volume.under.score
                  ) {
                    message =
                      '一次選考後の《不合格者》または《選考辞退》が出たため、【ボリューム】のコンディションがダウン…'
                  }
                } else if (diffCondition.score.speed) {
                  if (
                    oldCondition.score.speed.screen.score <
                    newCondition.score.speed.screen.score
                  ) {
                    message =
                      '書類選考結果の入力が《早かった》ため、【スピード】のコンディションがアップ！'
                  } else if (
                    oldCondition.score.speed.screen.score >
                    newCondition.score.speed.screen.score
                  ) {
                    message =
                      '書類選考結果の入力が《遅かった》ため、【スピード】のコンディションがダウン…'
                  } else if (
                    oldCondition.score.speed.adjust.score <
                    newCondition.score.speed.adjust.score
                  ) {
                    message =
                      '一次選考日が《早く》設定されたため、【スピード】のコンディションがアップ！'
                  } else if (
                    oldCondition.score.speed.adjust.score >
                    newCondition.score.speed.adjust.score
                  ) {
                    message =
                      '一次選考日が《遅く》設定されたため、【スピード】のコンディションがダウン…'
                  }
                }
              }
            }
            this.$store.commit('setCondition', newCondition)
          }
        }
      })
      return message
    },
  },
}
