<template>
  <div :class="['sideModal-wrapper', { active: isActive }]">
    <transition name="fade">
      <div
        v-if="value && !hideBg"
        class="sideModal-background"
        @click="$emit('bg-click')"
      />
    </transition>
    <transition name="slide">
      <div v-if="value" class="sideModal-container">
        <div class="sideModal-header">
          <slot name="header" />
        </div>
        <div class="sideModal-main">
          <slot name="main" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { delay } from 'lodash'

export default {
  name: 'SideModal',
  data() {
    return {
      isActive: false,
    }
  },
  props: {
    value: {
      type: Boolean,
    },
    hideBg: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue === true) {
        this.isActive = newValue
      } else {
        delay(() => {
          this.isActive = newValue
        }, 500)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$header-height: 56px;

.sideModal-wrapper {
  display: none;
  position: fixed;
  top: 0;
  z-index: 999;
  height: 0px;
  width: 0px;

  &.active {
    display: block;
    height: 100vh;
    width: 100%;
  }
  .sideModal-background {
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    position: absolute;
    width: 100%;
  }
  .sideModal-container {
    background: #fff;
    right: 0;
    position: absolute;
    bottom: 0;
    width: 60%;
    min-width: 714px;
    height: 100vh;
    .sideModal-header {
      z-index: 2;
      position: absolute;
      top: 0;
      width: 100%;
      height: $header-height;
      border-bottom: 1px solid map-deep-get($palette, black, black-30);
      background-color: white;
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    .sideModal-main {
      height: 100%;
      padding-top: $header-height;
      overflow-y: scroll;
      background-color: map-deep-get($palette, black, black-10);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-active,
.slide-leave-active {
  transform: translate(0px, 0px);
  transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100vw) translateX(0px);
  transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1) 0ms;
}
</style>
